import Bank99 from "../../../assets/banks/bank.99.svg";
import BankAustria from "../../../assets/banks/bank.austria.svg";
import Bausparkasse from "../../../assets/banks/bank.bausparkasse.svg";
import Bawag from "../../../assets/banks/bank.bawag.svg";
import Volksbank from "../../../assets/banks/bank.volksbank.svg";
import Wuestenrot from "../../../assets/banks/bank.wuestenrot.svg";
import HypoNoe from "../../../assets/banks/hypo.noe.svg";
import Oberbank from "../../../assets/banks/oberbank.svg";
import Raiffeisen from "../../../assets/banks/raiffeisen.svg";
import Sbauskasse from "../../../assets/banks/sb.auskasse.svg";

export const logos = [
    {
        Logo: BankAustria,
        alt: "Bank Austria",
        height: "30px",
        order: [0, 0, 0, 0],
        justifyContent: ["flex-start", "center"],
        opacity: 1,
        style: {
            filter: "grayscale(1)",
        },
    },
    {
        Logo: Volksbank,
        alt: "Volksbank",
        height: "22px",
        order: [3, 3, 3, 0],
        justifyContent: ["flex-end", "center"],
    },
    {
        Logo: Bawag,
        alt: "Bawag",
        height: "35px",
        order: [1, 1, 1, 0],
        justifyContent: ["flex-end", "center"],
    },
    {
        Logo: Sbauskasse,
        alt: "s Bausparkasse",
        height: "25px",
        order: [4, 4, 4, 0],
        justifyContent: ["flex-start", "center"],
    },
    {
        Logo: Raiffeisen,
        alt: "Raiffeisen",
        height: "29px",
        order: [6, 6, 6, 0],
        display: ["none", "none", "none", "flex"],
    },
    {
        Logo: Bausparkasse,
        alt: "start:bausparkasse",
        height: "25px",
        order: [5, 5, 5, 0],
        justifyContent: ["flex-end", "center"],
    },
    {
        Logo: Bank99,
        alt: "Bank99",
        height: "29px",
        order: [7, 7, 7, 0],
        display: ["none", "none", "none", "flex"],
    },
    {
        Logo: Wuestenrot,
        alt: "Wüstenrot",
        height: "24px",
        order: [8, 8, 8, 0],
        display: ["none", "none", "none", "flex"],
    },
    {
        Logo: HypoNoe,
        alt: "Hypo NOE",
        height: "23px",
        order: [9, 9, 9, 0],
        display: ["none", "none", "none", "flex"],
    },
    {
        Logo: Oberbank,
        alt: "Oberbank",
        height: "16px",
        order: [2, 2, 2, 0],
        justifyContent: ["flex-start", "center"],
    },
];
