import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import { ContentWrapper, H2, GreyBg, ButtonLink } from "../../assets/styles/base";
import { breakpoints } from "../../constants/breakpoints";
import TipsCard from "./TipsCard";

import "swiper/css";
import "swiper/css/pagination";
import { colors } from "../../constants/colors";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const TIPS_TO_SHOW = [
    "welcheNebenkostenBeimKredit",
    "wasBeiImmobilienfinanzierungBeachten",
    "kaufOderMieteGrundsatzfrage",
    "wasVerstehtManUnterUmschuldung",
];

const PromoteTips = ({ hasGreyBg, hasMarginTop }) => {
    const Content = (
        <StyledContentWrapper hasMarginTop={hasMarginTop}>
            <ColumnsWrapper>
                <IntroWrapper>
                    <H2>Wissenswertes zu deiner Immobilienfinanzierung.</H2>
                    <p>
                        Miracl kann dir zu all deinen Finanzierungsfragen helfen. Buche gleich einen unverbindlichen
                        Beratungstermin bei uns.
                    </p>
                    <Buttons>
                        <ButtonLink href="/gut-zu-wissen/">Alle Ideen</ButtonLink>
                        <ButtonLink transparentBg href={extendUrlWithSourceVersion(urls.survey)}>
                            Termin buchen
                        </ButtonLink>
                    </Buttons>
                </IntroWrapper>
                <CardsWrapper>
                    <Swiper
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        loop={true}
                        spaceBetween={32}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            1240: {
                                centeredSlides: false,
                                slidesPerView: 2,
                                spaceBetween: 26,
                            },
                        }}
                        modules={[Pagination]}
                        className="tipsSwiper"
                    >
                        {TIPS_TO_SHOW.map((id, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <TipsCard data={TIPS_AND_TRICKS_LIST[id]} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </CardsWrapper>
            </ColumnsWrapper>
        </StyledContentWrapper>
    );
    if (hasGreyBg) return <GreyBg>{Content}</GreyBg>;
    return Content;
};

const MultiColBreakMin = "1240px";

const StyledContentWrapper = styled(ContentWrapper)`
    font-family: "Inter", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    line-height: 1.5;
    color: #4b5563;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
`;

const ColumnsWrapper = styled.div`
    box-sizing: border-box;
    @media (min-width: ${MultiColBreakMin}) {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        max-width: 1400px;
        padding-left: 60px;
        padding-right: 60px;
    }
`;

const IntroWrapper = styled.div`
    max-width: 1400px;
    padding-left: 30px;
    padding-right: 30px;

    h2 {
        font-weight: 300;
    }
    p {
        margin: 0 0 28px;
    }

    @media (min-width: ${breakpoints.tabletBigMin}) {
        padding-left: 60px;
        padding-right: 60px;
    }

    @media (min-width: ${MultiColBreakMin}) {
        padding-top: 40px;
        padding-left: 0;
        padding-right: 0;
        width: 48%;

        h2 {
            margin-bottom: 30px;
        }
    }
`;

const CardsWrapper = styled.div`
    .swiper-pagination {
        display: flex;
        justify-content: center;
    }
    .swiper-slide {
        margin-top: 8px;
        margin-bottom: 40px;
        width: 260px;
    }
    .swiper-pagination-bullet {
        align-items: center;
        background: transparent;
        border-radius: 0;
        display: inline-flex;
        height: 20px;
        justify-content: center;
        margin-left: 2px !important;
        margin-right: 2px !important;
        width: 20px;

        &:before {
            background: #666;
            border-radius: 50%;
            content: "";
            display: block;
            height: 50%;
            width: 50%;
            transition: width ease-out 0.03s, height ease-out 0.03s;
        }
        &:hover:before {
            height: 100%;
            width: 100%;
        }
    }
    .swiper-pagination-bullet-active {
        background: transparent;

        &:before {
            background: ${colors.primary};
        }
    }

    @media (min-width: ${MultiColBreakMin}) {
        width: 48%;

        .swiper {
            padding: 0 13px;
            width: 572px;
        }
        .swiper-pagination {
            bottom: 0;
        }
    }
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    a {
        font-size: 13px;
        font-weight: 500;
        padding-left: 0;
        padding-right: 0;
        width: 48%;
    }

    @media (min-width: ${breakpoints.tabletMin}) {
        display: block;

        a {
            padding-left: 25px;
            padding-right: 25px;
            width: auto;

            &:first-child {
                margin-right: 15px;
            }
        }
    }

    @media (min-width: ${MultiColBreakMin}) {
        margin-bottom: 0;
    }
`;

export default PromoteTips;
