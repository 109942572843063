import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import check from "../../../assets/images/home/check.svg";
import stars from "../../../assets/images/home/stars.svg";
import { Box, Flex, Image, Text } from "../../../assets/styles/common";

const bannerList = [
    "Bankenvergleich & beste Konditionen",
    "Kostenlose & unverbindliche Beratung",
    "Schnell & unkompliziert",
];

const Banner = () => {
    const { bannerImage, bannerImage1920, bannerImage1280, bannerImage980, bannerImage600, bannerImage480 } =
        useStaticQuery(graphql`
            query {
                bannerImage: file(relativePath: { eq: "home/home-banner.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                    }
                }
                bannerImage1920: file(relativePath: { eq: "home/home-banner.1920w.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                    }
                }
                bannerImage1280: file(relativePath: { eq: "home/home-banner.1280w.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                    }
                }
                bannerImage980: file(relativePath: { eq: "home/home-banner.980w.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                    }
                }
                bannerImage600: file(relativePath: { eq: "home/home-banner.600w.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                    }
                }
                bannerImage480: file(relativePath: { eq: "home/home-banner.480w.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
        `);
    const desktopImages = withArtDirection(getImage(bannerImage1920), [
        {
            media: "(max-width: 1920px)",
            image: getImage(bannerImage),
        },
        {
            media: "(max-width: 1280px)",
            image: getImage(bannerImage1280),
        },
        {
            media: "(max-width: 980px)",
            image: getImage(bannerImage980),
        },
        {
            media: "(max-width: 600px)",
            image: getImage(bannerImage600),
        },
    ]);
    return (
        <Flex position="relative" overflow="hidden" w="100%">
            <Box width="100%" minHeight={["515px", "480px"]} maxHeight={["500px", "none"]}>
                <Box width="100%" height="100%" display={["none", "block"]}>
                    <GatsbyImage
                        image={desktopImages}
                        alt="Miracl"
                        layout="fullWidth"
                        position="relative"
                        imgStyle={{
                            width: "auto",
                            height: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                        }}
                        style={{
                            width: "auto",
                            height: "100%",
                        }}
                    />
                </Box>
                <Box width="100%" height="100%" display={["block", "none"]}>
                    <GatsbyImage
                        image={getImage(bannerImage480)}
                        alt="Miracl"
                        layout="fullWidth"
                        position="relative"
                        imgStyle={{
                            width: "auto",
                            height: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                        }}
                    />
                </Box>
            </Box>
            <Flex
                position="absolute"
                width="100%"
                height="100%"
                flexDirection="row"
                alignItems={["flex-start", "center"]}
                justifyContent="center"
                px={[5, 8, 4]}
                py={[12, 12, 4]}
                color="white"
                top={0}
                left={0}
            >
                <Flex width="100%" maxWidth="1440px" pb={[8, 8, 12, 14]}>
                    <Box mr={[6, 11]} display={["none", "initial"]}>
                        <Image src={stars} width={136} height={145} w={[50, 60, 80, 100, 136]} alt="Stars" />
                    </Box>
                    <Box>
                        <Text
                            maxWidth={["500px", null, null, "650px"]}
                            fontSize={["2xl", "3xl", null, null, "4xl", "5xl"]}
                        >
                            Immofinanzierung. Nur einfach.
                        </Text>
                        <Flex
                            flexDirection="column"
                            gap={3}
                            flexWrap="wrap"
                            fontSize={["xs", "xs", "sm"]}
                            mt={[6, 6, 8, 8, 8, 11]}
                            maxWidth={["180px", null, "none"]}
                        >
                            {bannerList.map((item, i) => (
                                <Flex alignItems="center" key={i}>
                                    <Image src={check} width={24} height={24} size={["20px", "24px"]} />
                                    <Text as="span" ml={2}>
                                        {item}
                                    </Text>
                                </Flex>
                            ))}
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
export default Banner;
