import * as React from "react";
import { Box, Button, Flex, Text } from "../../../assets/styles/common";
import FormController from "../../FormController";
import { NumberInput } from "../../FormElement/Input";
import { Select } from "../../FormElement/Select";
import { financingTypes, locations, runtime } from "./constants";
import { useForm } from "./useForm";

const numberInputProps = {
    allowNegative: false,
    thousandSeparator: ".",
    decimalSeparator: ",",
    suffix: " €",
    displayType: "number",
};
const errorProps = {
    height: { _: "auto", lg: 0 },
};

const SELECT_DEF_VALUE = "Wählen";

const CalculateForm = () => {
    const {
        initialValues,
        isUmschuldung,
        errors,
        onPriceChange,
        onFocus,
        onSubmit,
        onFinancingTypeChange,
        onSelectChange,
    } = useForm();

    return (
        <form onSubmit={onSubmit}>
            <Flex flexDirection="row" width="100%" flexWrap={{ _: "wrap", xl: "nowrap" }} gap={[4, 4, 4, 5, 6]}>
                <Box minWidth={{ _: "100%", sm: "245px", xl: "154px", "2xl": "220px" }} flexGrow={1}>
                    <Select
                        size="rxl"
                        label="Vorhaben"
                        name="financingType"
                        fontWeight="bold"
                        title="Vorhaben"
                        onChange={onFinancingTypeChange}
                        data-is-default={true}
                        defaultValue={initialValues.financingType}
                    >
                        <option disabled>{SELECT_DEF_VALUE}</option>
                        {financingTypes.map(({ value, title }, i) => (
                            <option key={i} value={value}>
                                {title}
                            </option>
                        ))}
                    </Select>
                </Box>
                <FormController
                    error={errors.price}
                    width={["50%", null, "auto"]}
                    minWidth={["100px", "130px", "205px"]}
                    flexGrow={1}
                    errorProps={errorProps}
                >
                    <NumberInput
                        size="rxl"
                        id="price"
                        label={isUmschuldung ? "Restschuld" : "Kaufpreis"}
                        name="price"
                        fontWeight="bold"
                        placeholder="z. B. 320.000 €"
                        type="tel"
                        title={isUmschuldung ? "Restschuld" : "Kaufpreis"}
                        options={numberInputProps}
                        defaultValue={initialValues.price}
                        onFocus={onFocus}
                        onValueChange={onPriceChange}
                    />
                </FormController>
                <Box width={["30%", null, "auto"]} minWidth={["114px", "85px", "150px"]} flexGrow={1}>
                    <Select
                        size="rxl"
                        label="Laufzeit"
                        name="runtime"
                        fontWeight="bold"
                        title="Laufzeit"
                        data-is-default={true}
                        defaultValue={initialValues.runtime}
                        onChange={onSelectChange}
                    >
                        <option disabled>{SELECT_DEF_VALUE}</option>
                        {runtime.map((value, i) => (
                            <option key={i} value={value}>
                                {value}
                            </option>
                        ))}
                    </Select>
                </Box>
                <Box minWidth={["180px"]} flexGrow={1} display={["none", null, null, "initial"]}>
                    <NumberInput
                        size="rxl"
                        id="income"
                        label="Haushaltseinkommen"
                        name="income"
                        fontWeight="bold"
                        placeholder="z. B. 3.000 €"
                        type="tel"
                        title="Haushaltseinkommen"
                        defaultValue={initialValues.income}
                        options={numberInputProps}
                        onFocus={onFocus}
                    />
                </Box>
                <Box
                    minWidth={{ _: "175px", xl: "154px", "2xl": "220px" }}
                    flexGrow={1}
                    display={["none", null, null, "initial"]}
                >
                    <Select
                        size="rxl"
                        label="Ort"
                        name="location"
                        fontWeight="bold"
                        title="Ort"
                        data-is-default={true}
                        defaultValue={initialValues.location}
                        onChange={onSelectChange}
                    >
                        <option disabled>{SELECT_DEF_VALUE}</option>
                        {locations.map(({ title, value }, i) => (
                            <option key={i} value={value}>
                                {title}
                            </option>
                        ))}
                    </Select>
                </Box>
                <Button
                    type="submit"
                    size="rxl"
                    fontSize={["md", "md", "lg"]}
                    px={4}
                    order={[5]}
                    flexGrow={1}
                    minWidth={{ _: "100%", xl: "190px", "2xl": "246px" }}
                >
                    Jetzt vergleichen
                    <Text
                        as="span"
                        fontSize="3xl"
                        fontWeight="300"
                        mt="-2px"
                        ml={3}
                        display={["initial", null, null, "none"]}
                    >
                        &#x203A;
                    </Text>
                </Button>
            </Flex>
        </form>
    );
};
export default CalculateForm;
