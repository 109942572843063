import React from "react";
import { Helmet } from "react-helmet";

const LogoStructuredData = ({ siteUrl }) => {
    const logoStructuredDataSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "miracl",
        url: `${siteUrl}`,
        logo: `${siteUrl}/og-image-miracl.png`,
    };

    return (
        <Helmet>
            {Object.keys(logoStructuredDataSchema).length && (
                <script type="application/ld+json">{JSON.stringify(logoStructuredDataSchema)}</script>
            )}
        </Helmet>
    );
};

export default LogoStructuredData;
