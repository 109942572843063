import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Button } from "../../../assets/styles/base";
import { ButtonLink, ButtonOutlineLink, Flex, Text } from "../../../assets/styles/common";
import { breakpoints } from "../../../constants/breakpoints";
import { urls, extendUrlWithSourceVersion } from "../../../constants/urls";

const ProductExplainer = () => {
    const { avatars, product1, product2, product3 } = useStaticQuery(graphql`
        query {
            avatars: file(relativePath: { eq: "productExplainer/avatars-x2.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], width: 137, height: 33)
                }
            }
            product1: file(relativePath: { eq: "productExplainer/row-1x2.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                }
            }
            product2: file(relativePath: { eq: "productExplainer/row-2x2.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                }
            }
            product3: file(relativePath: { eq: "productExplainer/row-3x2.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                }
            }
        }
    `);
    return (
        <Wrapper>
            <Container>
                <Row>
                    <Item>
                        <GatsbyImageStyled
                            image={getImage(product1)}
                            alt="Berechne deine Kreditrate"
                            width="720"
                            height="812"
                        />
                    </Item>

                    <TextItem>
                        <PreHeading>Bankenvergleich & beste Konditionen</PreHeading>
                        <Heading>
                            Wir finden die optimale
                            <br />
                            Finanzierung für dich.
                        </Heading>
                        <p>
                            Egal ob neuer Kredit oder Umschuldung: Wir vergleichen Österreichs führende Banken, um dir
                            die besten Konditionen einzuholen.
                        </p>
                        <p>
                            Mit Hilfe unseres Algorithmus finden die miracl Finanzierungsspezialisten Lösungen, sogar
                            für besonders schwierige Fälle.
                        </p>
                        <Bottom>
                            <ButtonLink
                                background="#2563EB"
                                href={extendUrlWithSourceVersion(urls.survey)}
                                size="lg"
                                arrowRight
                                borderRadius={38}
                                px={4}
                                style={{ fontSize: 16 }}
                            >
                                Zum Vergleich
                            </ButtonLink>
                            <ButtonOutlineLink
                                color="#2563EB"
                                href={extendUrlWithSourceVersion(urls.survey)}
                                size="lg"
                                borderRadius={38}
                                px={4}
                                style={{ fontSize: 16 }}
                            >
                                Termin vereinbaren
                            </ButtonOutlineLink>
                        </Bottom>
                    </TextItem>
                </Row>
                <Row>
                    <TextItem>
                        <PreHeading>Kostenlose & unverbindliche Beratung</PreHeading>
                        <Heading>Wir sind jederzeit für dich erreichbar.</Heading>

                        <p>
                            In einem kostenlosen Beratungsgespräch per Telefon oder Videoanruf gehen unsere
                            Finanzierungsspezialisten auf deine persönlichen Bedürfnisse und Umstände ein. So finden wir
                            den optimalen Kredit für dich.
                        </p>

                        <Bottom style={{ justifyContent: "space-between" }}>
                            <Flex alignItems="center" columnGap="12px">
                                <ImageAvatars image={getImage(avatars)} alt="Miracl" width="107" height="33" />
                                <Text color="#6B7280" fontSize="14px" width="100%">
                                    <a href="https://miracl.at/ueber-uns/">
                                        Unser Beratungs-
                                        <br />
                                        und Finanzteam
                                    </a>
                                </Text>
                            </Flex>
                            <div>
                                <a href={extendUrlWithSourceVersion(urls.survey)}>
                                    <ArrowButton
                                        color="#2563EB"
                                        fontWeight="400"
                                        size="lg"
                                        arrowRight
                                        style={{ fontSize: 16 }}
                                    >
                                        Termin vereinbaren
                                    </ArrowButton>
                                </a>
                            </div>
                        </Bottom>
                    </TextItem>
                    <Item reorder>
                        <GatsbyImageStyled
                            image={getImage(product2)}
                            alt="Kostenloses Beratungsgespräch planen"
                            width="720"
                            height="659"
                        />
                    </Item>
                </Row>
                <Row>
                    <Item>
                        <GatsbyImageStyled
                            image={getImage(product3)}
                            alt="Immobilienkredit - dein Status"
                            width="720"
                            height="785"
                        />
                    </Item>
                    <TextItem>
                        <PreHeading>Das Rundum-sorglos-Paket für deine Finanzierung</PreHeading>
                        <Heading>Schnell, online und unkompliziert.</Heading>
                        <p>
                            Mit miracl erledigst du den gesamten Finanzierungsprozess gemütlich von zu Hause – vom
                            Kreditangebot bis zum Notartermin.
                        </p>
                        <p>
                            Verfolge deinen Kreditstatus, lade deine Dokumente hoch oder kontaktiere deinen persönlichen
                            Finanzierungsspezialisten über unseren miracl hub.
                        </p>
                        <Bottom>
                            <ButtonLink
                                background="#2563EB"
                                href={extendUrlWithSourceVersion(urls.survey)}
                                size="lg"
                                arrowRight
                                borderRadius={38}
                                px={4}
                                style={{ fontSize: 16 }}
                            >
                                Termin vereinbaren
                            </ButtonLink>
                        </Bottom>
                    </TextItem>
                </Row>
            </Container>
        </Wrapper>
    );
};
export default ProductExplainer;

const Wrapper = styled.div`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    background-color: #f9fafb;
    overflow: hidden;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12rem;
    padding: 8rem 0;
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;

    @media (max-width: ${breakpoints.mobileBigMax}) {
        row-gap: 0;
        padding: 0;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 70px;

    @media (max-width: ${breakpoints.tabletMax}) {
        margin: 70px 0;
        gap: 20px;
    }
    @media (max-width: ${breakpoints.mobileBigMax}) {
        flex-direction: column;
        margin: 45px 0;
        gap: 20px;
    }
`;

const Item = styled.div`
    width: 50%;
    flex: 0 1 auto;

    @media (max-width: ${breakpoints.mobileBigMax}) {
        width: 100%;
        ${({ reorder }) => `order: ${reorder ? 0 : 1}`}
    }
`;

const TextItem = styled(Item)`
    padding: 0 2rem;
`;

const GatsbyImageStyled = styled(GatsbyImage)`
    width: 100%;
    height: auto;
    margin: 0;
`;
const ImageAvatars = styled(GatsbyImage)`
    width: 174px;
    height: auto;

    @media (max-width: ${breakpoints.mobileBigMax}) {
        width: 150px;
    }
`;

const Bottom = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 3.5rem;
    column-gap: 24px;
    row-gap: 16px;
    flex-wrap: wrap;
`;

const PreHeading = styled.div`
    font-size: 0.9rem;
    font-weight: 500;
    color: #2563eb;
    margin-bottom: 0.75rem;
`;

const Heading = styled.h2`
    font-size: 1.85rem;
    margin-bottom: 3rem;
    font-weight: 300;
    color: #111827;

    @media (max-width: ${breakpoints.mobileBigMax}) {
        font-size: 1.5rem;
    }
`;

const ArrowButton = styled(Button)`
    background: transparent;
    padding-right: 64px;
    padding-left: 0.25rem;

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        padding: 26px;
        right: 1px;
        background-color: rgba(37, 99, 235, 0.1);
        border-radius: 50%;
    }
`;
