import React from "react";
import styled from "styled-components";
import { EffectFade, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ButtonLink, ContentWrapper, GreyBg } from "../../assets/styles/base";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

import { SUCCESS_STORIES_LIST } from "../../constants/successStoriesList";

const STORIES_TO_SHOW = ["AnnaUndPaul", "Daniela", "Beate"];

const PromoteStories = ({ hasGreyBg, hasMarginTop }) => {
    const { AnnaUndPaulImage, DanielaImage, BeateImage } = useStaticQuery(graphql`
        query {
            AnnaUndPaulImage: file(relativePath: { eq: "success-stories/images/preview/anna-und-paul.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
            }
            DanielaImage: file(relativePath: { eq: "success-stories/images/preview/daniela.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
            }
            BeateImage: file(relativePath: { eq: "success-stories/images/preview/beate.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
            }
        }
    `);
    const getPromoteStoriesImage = (id) => {
        let gasbyImage;
        switch (id) {
            case "AnnaUndPaul":
                gasbyImage = AnnaUndPaulImage;
                break;
            case "Daniela":
                gasbyImage = DanielaImage;
                break;
            default:
                gasbyImage = BeateImage;
                break;
        }

        return getImage(gasbyImage);
    };

    const Content = (
        <StyledContentWrapper hasMarginTop={hasMarginTop}>
            <StoriesWrapper>
                <Swiper
                    centeredSlides={true}
                    slidesPerView={1}
                    loop={true}
                    spaceBetween={0}
                    speed={400}
                    effect="fade"
                    fadeEffect={{
                        crossFade: true,
                    }}
                    navigation={{
                        nextEl: ".swiperButtonNext",
                        prevEl: ".swiperButtonPrev",
                    }}
                    breakpoints={{
                        1000: {
                            speed: 800,
                        },
                    }}
                    modules={[EffectFade, Navigation]}
                >
                    {STORIES_TO_SHOW.map((id, index) => {
                        const storyData = SUCCESS_STORIES_LIST[id];
                        return (
                            <SwiperSlide key={index}>
                                <Story>
                                    <ImageWrapper>
                                        <GatsbyImage image={getPromoteStoriesImage(id)} alt={storyData.name} />
                                    </ImageWrapper>
                                    <StoryContent>
                                        <p>{storyData.storyShort}</p>
                                        <Info>{`${storyData.date} | ${storyData.name}`}</Info>
                                        <ButtonsWrapper>
                                            <Buttons>
                                                <ButtonLink blue href={storyData.url}>
                                                    Artikel lesen
                                                </ButtonLink>
                                                <ButtonLink
                                                    className="moreBtn"
                                                    blue
                                                    transparentBg
                                                    href="/unsere-kunden/"
                                                >
                                                    Alle anzeigen
                                                </ButtonLink>
                                                <Arrows>
                                                    <div className="swiperButtonPrev">
                                                        <span />
                                                    </div>
                                                    <div className="swiperButtonNext">
                                                        <span />
                                                    </div>
                                                </Arrows>
                                            </Buttons>
                                            <MobileAllStoriesLink href="/unsere-kunden/">
                                                Alle Erfahrungen
                                            </MobileAllStoriesLink>
                                        </ButtonsWrapper>
                                    </StoryContent>
                                </Story>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </StoriesWrapper>
        </StyledContentWrapper>
    );
    if (hasGreyBg) return <GreyBg>{Content}</GreyBg>;
    return Content;
};

const DesktopBreakMin = "1022px";
const Buttons1LineMin = "520px";

const StyledContentWrapper = styled(ContentWrapper)`
    color: #111827;
    font-family: "Inter", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    line-height: 1.5;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
`;

const ImageWrapper = styled.div`
    max-width: 100%;

    img {
        max-width: 100%;
    }

    @media (min-width: ${DesktopBreakMin}) {
        width: 48%;
    }
`;

const Story = styled.div`
    margin: 0 auto;
    max-width: 600px;

    @media (min-width: ${DesktopBreakMin}) {
        display: flex;
        justify-content: space-between;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 60px;
        padding-right: 60px;
    }
`;

const Info = styled.div`
    color: #9ca3af;
    font-size: 13px;
`;

const Arrows = styled.div`
    align-items: center;
    display: flex;
    padding: 0 20px;

    @media (min-width: ${Buttons1LineMin}) {
        margin-left: auto;
    }

    .swiperButtonNext {
        margin-left: 40px;
    }
    .swiperButtonNext,
    .swiperButtonPrev {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 36px;
    }
    .swiperButtonNext span,
    .swiperButtonPrev span {
        background: #9ca3af;
        cursor: pointer;
        display: block;
        height: 2px;
        margin: 0;
        position: relative;
        width: 20px;

        &:before,
        &:after {
            content: "";
            background: #9ca3af;
            position: absolute;
            height: 2px;
            width: 15px;
        }
    }

    .swiperButtonNext span {
        &:before {
            right: -3px;
            bottom: -5px;
            transform: rotate(-45deg);
        }

        &:after {
            right: -3px;
            top: -5px;
            transform: rotate(45deg);
        }
    }
    .swiperButtonPrev span {
        &:before {
            left: -3px;
            top: -5px;
            transform: rotate(-45deg);
        }

        &:after {
            left: -3px;
            bottom: -5px;
            transform: rotate(45deg);
        }
    }
`;

const ButtonsWrapper = styled.div`
    box-sizing: border-box;
    margin-top: 40px;
    overflow: hidden;
    width: 100%;
`;

const StoryContent = styled.div`
    overflow: hidden;
    padding: 0 30px;

    p {
        margin: 25px 0 10px;
    }

    @media (min-width: ${DesktopBreakMin}) {
        padding-bottom: 0;
        width: 48%;
    }
`;

const StoriesWrapper = styled.div`
    box-sizing: border-box;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    a {
        font-size: 13px;
        font-weight: 500;
        white-space: nowrap;

        &.moreBtn {
            display: none;
            margin-left: 18px;

            @media (min-width: ${Buttons1LineMin}) {
                display: block;
            }
        }
    }

    @media (min-width: ${Buttons1LineMin}) {
        justify-content: flex-start;
    }
`;

const MobileAllStoriesLink = styled.a`
    color: {urls.blue};
    float: right;
    font-size: 14px;
    text-decoration: underline;

    @media (min-width: ${Buttons1LineMin}) {
        display: none;
    }
`;

export default PromoteStories;
